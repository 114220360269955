<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <router-link to="/profile/phones" type="button">
          <i class="dropdown-icon si si-arrow-left"></i>
        </router-link>
        <h3 class="card-title">Telefon Ekle</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <label class="form-label">Başlık</label>
              <input
                type="text"
                class="form-control"
                name="title"
                placeholder="Başlık"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <label class="form-label">Telefon Numarası</label>
              <input
                type="text"
                class="form-control"
                name="phone"
                v-mask="'0(###) ###-##-##'"
                v-model="phone"
                placeholder="0(###) ###-##-##"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button
          type="button"
          @click="save"
          id="saveButton"
          class="btn btn-primary"
        >
          Kaydet
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    document.title = "Telefonlarım";
  },
  data() {
    return {
      phone: "",
      phoneRegex: new RegExp(
        "^[+]*[0]*[ ]{0}[(][5]{1}[0-9]{1,3}[ ]{0,1}[)]{0,1}[ ]{0,1}[0-9]{1,3}[ ]{0,1}[0-9]{2}[-]{0,1}[0-9]{2}[]{0,1}[-./]{0,1}[-]{0,1}[0-9]{1,5}$"
      ),
    };
  },
  methods: {
    save() {
      let title = document.querySelector("input[name=title]").value;
      let number = this.phone;
      if (title == null || title == "") {
        this.$vToastify.warning("Telefon başlığı girilmedi", "Uyarı!");
      } else if (number == null || number == "") {
        this.$vToastify.warning("Telefon numarası girilmedi", "Uyarı!");
      } else if (!number.match(this.phoneRegex)) {
        this.$vToastify.warning("Hatalı telefon numarası", "Uyarı!");
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML =
          "Bilgiler kayıt ediliyor..";
        let phoneSave = {
          title: title,
          number: number,
        };
        this.$store.dispatch("phoneCreate", phoneSave).then((value) => {
          if (value.type == "error") {
            if (value.message == "ERRxUAR") {
              this.$vToastify.warning(
                "Onay sürecindeki profil güncellenemez",
                "Uyarı!"
              );
            }
          } else {
            this.$vToastify.success(
              "Telefon başarılı bir şekilde kaydedildi",
              "Başarılı!"
            );
            this.$router.push("/profile/phones/update/" + value.created_id);
          }
        });
      }
    },
  },
  components: {},
  mounted() {},
};
</script>